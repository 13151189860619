import BaseAPI from "./BaseAPI";

import { URLS } from "./Endpoints";
import { GenericAPI } from "./GenericAPI";

class UserAPI extends GenericAPI {

  /**
   * Request que busca user por uuid
   * @param  {string} uuid - uuid do usuário
   */
  static async getUser(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.USER}/${uuid}`);
      if (response.status === 200) return response.data;
      return this._exception(response, "Erro ao buscar usuário.");
    } catch (e) {
      return this._exception(e, "Falha ao buscar usuário.");
    }
  }

  /**
   * Request que busca separador por uuid
   * @param  {string} uuid - uuid do usuário do separador
   */
  static async getSeparator(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.USER}/${uuid}/separator`);
      if (response.status === 200) return response.data;
      return this._exception(response, "Erro ao buscar separador.");
    } catch (e) {
      return this._exception(e, e?.response.status === 404 ? "Qr Code de separador inválido" : "Falha ao buscar separador.");
    }
  }

  /**
   * Request que busca separador por uuid
   * @param  {string} uuid - uuid do usuário do separador
   */
  static async getCustomer(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.USER}/${uuid}/customer`);
      if (response.status === 200) return response.data;
      return this._exception(response, "Erro ao buscar cliente.");
    } catch (e) {
      return this._exception(e, "Falha ao buscar cliente.");
    }
  }
}

export default UserAPI;

const VERSION = "/v1";

export const URLS = {
  VERSION,
  USER: `${VERSION}/user`,
  COMPANY: `${VERSION}/company`,
  ORGANIZATION: `${VERSION}/company/organization`,
  LOCKER: `${VERSION}/locker`,
  FLOW: `${VERSION}/flow`,
  ALLOCATION: `${VERSION}/allocation`,
  WEBHOOKLOGGER: `${VERSION}/integrations/webhook-logger`,
};

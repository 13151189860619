/* eslint-disable */

/*
    Config: Configurações para teste local, stating e  Prod.
    Por fazer conexão com backend e com o Locker (local) é necessário dois ambientes para cada configuração;
    As configurações:

    prod: Backend Prod e Locker Prod. Test is false.

    staging: Backend STAGING e Locker Prod.
    stagingLocal: Backend STAGING e Mock LOCAL do Locker.

    local: Backend LOCAL e Mock LOCAL do Locker.
*/

const prod = { test: false };

// SERVER: STAGING
// LOCKER: PROD.
const staging = { test: false };

const config = {
  ...prod,
  // url: "https://lass-api.stoom.com.br",
  url: "https://lass-api.dev.stoomlab.com.br",
  // url: "http://localhost:8000",
  // urllocker: "http://localhost:8005",
  urllocker: "https://lass-laurenti-api.dev.stoomlab.com.br",
};


export default config;

import axios from "axios";

import config from "../config";


export default class WebhookAPI {
  /**Métodos get */
  static async get(endpoint, data = {}) {
    const requestConfig = { params: data };
    return await api.get(endpoint, requestConfig);
  }

  /**Métodos post */
  static async post(endpoint, data = {}) {
    return await api.post(endpoint, data);
  }

  /**Método Put */
  static async put(endpoint, data = {}) {
    return await api.put(endpoint, data);
  }

  /**Método Patch */
  static async patch(endpoint, data = {}, config) {
    return await api.patch(endpoint, data, config);
  }

  /**Método delete */
  static async delete(endpoint, data = {}) {
    return await api.delete(endpoint, { data });
  }
}

const api = axios.create({
  baseURL: config.url,
});



